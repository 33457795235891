/**
 * 404 page
 * https://lmliff.cht.com.tw/404
 */
import { Link } from 'gatsby';

import { styled } from '@/utils/styledComponents';

const Wrapper = styled.main`
  color: '#232129';
  padding: '96px';
`;

const Header = styled.h1`
  margin-top: 0;
  margin-bottom: 64;
  max-width: 320;
`;

const Description = styled.p`
  margin-bottom: 48;
`;

const CodeBlock = styled.code`
  color: '#8A6534';
  padding: 4;
  background-color: '#FFF4DB';
  font-size: '1.25rem';
  border-radius: 4;
`;

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Header>Page not found</Header>
      <Description>
        Sorry 😔, we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <CodeBlock>src/pages/</CodeBlock>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </Description>
    </Wrapper>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
